import { Alert, Box, Button, Card, Grid, MenuItem, Modal, Snackbar, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { IProduct } from '../../../api/interfaces/interfaces';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CampaignIcon from '@mui/icons-material/Campaign';
import Badge from '@mui/material/Badge';
import {
	currencyFormatter,
	dateAndTimeFormat,
	ensureLocation,
} from '../../../functions/CommonFunctions';
import moment from 'moment';
import { useState } from 'react';

interface ProductInfoInterface {
	product: IProduct;
}

export const ProductInfo = (props: ProductInfoInterface) => {
	const {
		product: { name, price, condition, state, city, publishedAt },
	} = props;
	// modal de confirmación report publication
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [notification, setNotifation] = useState(false);
	const closePopUp = () => {
		setNotifation(false);
	};
	const options = [
		{ value: 'inappropriate', label: 'Contenido inapropiado' },
		{ value: 'spam', label: 'Spam' },
		{ value: 'other', label: 'Otro' },
	];
	const [reportType, setReportType] = useState('');
	const [description, setDescription] = useState('');
	const handleReportTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setReportType(event.target.value);
	};

	const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDescription(event.target.value);
	};

	const handleSubmit = () => {
		// Aquí puedes manejar el envío del reporte
		setNotifation(true);
		handleClose();
	};
	const reportTheme = createTheme({
		palette: {
			success: {
				main: '#66BB6A', // Color principal personalizado para success
				contrastText: '#fff', // Color del texto en el botón de éxito
			},
		},
	});

	const theme = createTheme({
		palette: {
			warning: {
				// reparado
				main: '#ffffff',
				contrastText: 'orange',
			},
			info: {
				main: '#ffffff', // usado
				contrastText: 'red',
			},
			success: {
				main: '#ffffff', // nuevo
				contrastText: 'green',
			},
		},
	});


	/// funcion para cuando el cliente ingrese productos SOLO EN MAYUSCULAS
	const formatWord = (word: string) => {
		const hasMultipleUppercase = word.split('').filter(char => char === char.toUpperCase()).length > 1;
		if (hasMultipleUppercase) {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		}
		return word;
	};

	// Función para formatear todo el texto
	const formatText = (text: string | undefined) => {
		if (!text) return '';
		return text
			.split(' ')
			.map(formatWord)
			.join(' ');
	};

	const formatProductName = formatText(name);


	return (
		<Card
			sx={{
				borderRadius: '4px',
				justifyContent: 'center',
				backgroundColor: '#101010',
				paddingLeft: 1,
				paddingBottom: 1,
				paddingTop: 2,
				paddingRight: 3,
			}}>
			<Grid container direction="column" justifyContent="center" alignItems="flex-start">
				<Grid item display="flex" style={{ width: '100%' }}>
					<Typography fontWeight="bold" textAlign="initial" marginTop={1.6} color="#f1f1f1" style={{ width: '100%' }}>
						{formatProductName}
					</Typography>
					<ThemeProvider theme={theme}>
						<Badge slotProps={{
							badge: {
								style: {
									border: `2px solid ${condition === 'Nuevo' ? 'green' : condition === 'Usado' ? 'red' : 'yellow'}`,
									color: 'white'
								},
							},
						}} badgeContent={condition} style={{ marginLeft: '-10px' }} />
					</ThemeProvider>
				</Grid>
				<Grid container alignItems="center" spacing={1}>
					<Grid item xs={11} marginLeft={-1} marginTop={.8}>
						<Typography marginLeft={1} textAlign="left" color="#f1f1f1" fontFamily="Roboto">
							<MonetizationOnIcon sx={{ verticalAlign: 'middle', color: 'white', mr: .5 }} fontSize="medium" />
							{currencyFormatter({ value: price })}
						</Typography>
					</Grid>
				</Grid>
				<Grid container alignItems="center" spacing={1} marginTop="1px">
					<Grid item xs={11} marginLeft={-1} marginTop={.3}>
						<Typography marginLeft={1} textAlign="left" color="#f1f1f1" fontSize=".9rem" fontFamily="Roboto">
							<LocationOnIcon sx={{ verticalAlign: 'middle', color: 'white', mr: .5 }} fontSize="medium" />
							{ensureLocation(city!, state!)}
						</Typography>
					</Grid>
				</Grid>
				<Grid container alignItems="center" spacing={1} marginTop="1px">
					<Grid item xs={11} marginLeft={-1} marginTop={.3}>
						<Typography marginLeft={1} textAlign="left" color="#f1f1f1" fontSize=".9rem" fontFamily="Roboto" whiteSpace='nowrap'>
							<CalendarTodayIcon sx={{ verticalAlign: 'middle', color: 'white', mr: .5 }} fontSize="medium" />
							Publicado: &nbsp;
							{publishedAt ? dateAndTimeFormat(publishedAt!) : moment().format('DD/MM/YYYY')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Typography align="left">
				<Button
					variant="contained"
					size="medium"
					sx={{
						marginTop: { xs: 2.5, sm: 2 },
						paddingX: 1.5,
						paddingY: .25,
						borderRadius: '14px',
						backgroundColor: '#f02e38',
						color: '#ffffff',
						textTransform: 'none',  // Mantener el texto en formato normal (no el formato button)
						fontWeight: 'bold',
						fontSize: '.8rem',
						'&:hover': {
							backgroundColor: '#f94d53',
						},
						'& .MuiButton-startIcon': {
							marginRight: 1,
						}
					}}
					onClick={handleOpen}
					startIcon={<CampaignIcon sx={{ mb: '.15rem' }} />}
				>
					Denunciar publicación
				</Button>
			</Typography>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					backgroundColor: "#111111",
					border: '2px solid #000',
					boxShadow: 24,
					p: 4,
					borderRadius: '14px'
				}}>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: 'white' }}>
							Reportar publicación
						</Typography>
					</Box>
					<TextField
						select
						label="Tipo de reporte"
						value={reportType}
						onChange={handleReportTypeChange}
						fullWidth
						margin="normal"
						InputLabelProps={{ style: { color: 'white' } }}
						InputProps={{
							style: { color: 'white' },
							sx: {
								'& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
								'&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
								'&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
								'& .MuiSvgIcon-root': { color: 'white' }
							},
						}}
						SelectProps={{
							MenuProps: {
							  PaperProps: {
								sx: {
								  backgroundColor: '#111111',
								},
							  },
							},
						  }}
					>
						{options.map((option) => (
							<MenuItem key={option.value} value={option.value} sx={{ color: 'white' }}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
					<TextField
						label="Descripción (opcional)"
						multiline
						rows={4}
						value={description}
						onChange={handleDescriptionChange}
						fullWidth
						margin="normal"
						InputLabelProps={{ style: { color: 'white' } }}
						InputProps={{
							style: { color: 'white' },
							sx: {
								'& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
								'&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
								'&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								}
							}}}
					/>
					<Box sx={{ display: 'flex', justifyContent: 'center', mt: 1.5 }}>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmit}
							disabled={!(reportType && description.length > 10)}
							sx={{'&.Mui-disabled': {
								bgcolor: '#111',
								color: 'white',
								opacity: 0.5
							}}}
						>
							Enviar
						</Button>
					</Box>
				</Box>
			</Modal>

			<ThemeProvider theme={reportTheme}>
				<Snackbar
					open={notification}
					autoHideDuration={4000}
					onClose={closePopUp}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
					<Alert
						onClose={closePopUp}
						severity="success"
						variant="filled"
						sx={{ width: { xs: '80%', sm: '100%' }, mt: '2.8rem' }}
					>
						Publicación denunciada con éxito
					</Alert>
				</Snackbar>
			</ThemeProvider>
		</Card>
	);
};

/*<Grid item display={'flex'}>
					<Typography color={'#f1f1f1'} style={{ margin: '1vh' }}>
						<MonetizationOnIcon sx={{ verticalAlign: 'middle' }} />
						{currencyFormatter({ value: price })}
					</Typography>
		</Grid>
				



				<Grid item display={'flex'}>
					<Typography color={'#f1f1f1'} style={{ margin: '1vh' }}>
						<LocationOnIcon sx={{ verticalAlign: 'middle' }} />
						{ensureLocation(city!, state!)}
					</Typography>
	</Grid>
				



				{/*<Grid item display={'flex'}>
					<Typography color={'#f1f1f1'} style={{ margin: '1vh' }}>
						<CalendarTodayIcon sx={{ verticalAlign: 'middle' }} />
						Publicado: {publishedAt ? dateAndTimeFormat(publishedAt!) : moment().format('DD/MM/YYYY')}
					</Typography>
</Grid>*/

// import Cookies from "universal-cookie";
import { ILoginUser, INewUser, IUser } from "./interfaces/interfaces";
import { axiosService } from "./axiosService";
import { UserContextType } from "../provider/UserContext";

// const cookies = new Cookies(null, { path: "/" });
// const userContext = UseUserContext();

export const login = async (
  loginData: ILoginUser,
  userContext: UserContextType
): Promise<any> => {
  const { setName, setUserImage } = userContext;
  const result = await axiosService
    .post("auth/login", loginData)
    .then((response: any) => {
      const {
        status,
        data: {
          name,
          image,
        },
      } = response;

      if (status !== 200)
        return { status: 401, name: "", image: "" };
      setName(name);
      setUserImage(image);
      localStorage.setItem("isAuthenticated", "true");
      return { status, name, image };
    })
    .catch((err: string) => {
      console.error("Error en login: " + err);
      return { status: 401, name: "", image: "" };
    });

  return result;
};

// export const facebookLogin = async (
//   accessToken: string,
//   userContext: UserContextType
// ): Promise<any> => {
//   const { setToken, setName, setUserID, setRefreshToken } = userContext;
//   const result = await axiosService
//     .post(`auth/facebookAuth?access_token=${accessToken}`)
//     .then((response) => {
//       const {
//         status,
//         data: {
//           token,
//           userName,
//           userLastname,
//           userImage,
//           userID,
//           refreshToken,
//         },
//       } = response;
//       setToken(token);
//       setName(userName);
//       setUserID(userID);
//       setRefreshToken(refreshToken);
//       return response;
//     })
//     .catch((err) => {
//       console.error("Error en login: " + err);
//       return { status: 401, name: "", lastname: "" };
//     });

//   return result;
// };

export const register = async (dataUser: INewUser): Promise<any> => {
  const response = await axiosService
    .post("users/createnewuser", dataUser)
    .then()
    .catch((err) => {
      console.error("Error al registrar usuario: " + err);
      return err;
    });
  return response;
};
export const checkSessionStatus = async (): Promise<any> => {
  const response = await axiosService
  .get("auth/checkSession")
  .then()
  .catch((err) => {
    console.error("Error al registrar usuario: " + err);
    return err;
  });
return response;
};

export const checkToken = async (): Promise<any> => {
  const response = await axiosService
    .get("auth/checkToken")
    .then()
    .catch((err) => {
      console.error("Error al registrar usuario: " + err);
      return err;
    });
  return response;
};

export const refreshToken = async (
  userContext: UserContextType
): Promise<any> => {
  // const { setToken } = userContext;
  const response = await axiosService
    .post("auth/generateNewToken")
    .then((response: any) => {
      // const {
      //   data: { accessToken },
      // } = response;
      // setToken(accessToken);
      return response;
    })
    .catch((err) => {
      console.error("Error al registrar usuario: " + err);
      return err;
    });
  return response;
};

export const getUsers = async (): Promise<IUser[]> => {
  const data = await axiosService
    .get("users")
    .then((response) => {
      if (response.status === 200) return response.data;
      else {
        console.warn(response);
        return {};
      }
    })
    .catch((err) => {
      console.error("Error al registrar usuario: " + err);
    });
  return data;
};

export const sendRecoveryPassword = async (email: string): Promise<string> => {
  const data = await axiosService
    .post("auth/recovery", { email: email })
    .then((response) => {
      if (response.status === 200) return response.data.message;
      else return "Ha ocurrido un error.";
    })
    .catch((err) => {
      return "Ha ocurrido un error.";
      // console.error("Error al registrar usuario: " + err);
    });
  return data;
};
export const sendNewPassword = async (token: string, password: string): Promise<{status: number, message: string}> => {
  const data = await axiosService
    .post("auth/change-password", { token: token, newPassword: password })
    .then((response) => {
      if (response.status === 200) return {status: response.status, message: response.data.message};
      else return {status: response.status, message: "Ha ocurrido un error."};
    })
    .catch((err) => {
      return {status: 409, message: "Ha ocurrido un error."};
      // console.error("Error al registrar usuario: " + err);
    });
  return data;
};

export const verifyEmail = async (token: string): Promise<{status: number, message: string}> => {
  const data = await axiosService
    .post("users/verifyEmail", { token })
    .then((response) => {
      if (response.status === 201) return {status: response.status, message: response.data.msg};
      else return {status: response.status, message: "Ha ocurrido un error."};
    })
    .catch((err) => {
      return {status: 409, message: "Ha ocurrido un error."};
      // console.error("Error al registrar usuario: " + err);
    });
  return data;
};

export const logout = async (userContext: UserContextType): Promise<any> => {
	const response = await axiosService
	.get("auth/logout");
  userContext.setName("");
  userContext.setUserImage("");
	return true;
  };

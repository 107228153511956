import { createContext, useContext } from 'react';

export type UserContextType = {
	name: string;
	setName: (value: string) => void;
	userImage: string;
	setUserImage: (value: string) => void;
	logOut: () => void;
};

export const UserContext = createContext<UserContextType>({
	name: '',
	setName: () => {},
	userImage: '',
	setUserImage: () => {},
	logOut: () => {},
});

export const UseUserContext = () => useContext(UserContext);

import { useCallback, useMemo, useState } from "react";
import { UserContext } from "./UserContext";
import Cookies from "universal-cookie";

interface UserProviderProps {
  children: React.ReactNode;
}

export type UserContextType = {
  name: string;
  setName: (value: string) => void;
  userImage: string;
  setUserImage: (value: string) => void;
  logOut: () => void;
};
const cookies = new Cookies(null, { path: "/" });

const UserProvider = ({ children }: UserProviderProps) => {
  const [name, setName_] = useState(cookies.get("name"));
  const [userImage, setUserImage_] = useState(localStorage.getItem("userImage") ?? '');

  const setName = (name: string) => {
    cookies.set("name", name);
    setName_(name);
  };

  const setUserImage = (image: string) => {
    localStorage.setItem("userImage", image);
    setUserImage_(image);
  };

  const logOut = useCallback(() => {
    setName('');
    setUserImage('');
  },[]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      name,
      setName,
      userImage,
      setUserImage,
      logOut,
    }),
    [name, userImage, logOut]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;

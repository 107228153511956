import { Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { IPersonalInformation } from '../../../api/interfaces/interfaces';
import { updatePersonalInformation } from '../../../api/userApi';
interface PersonalTabProps {
	personalData: IPersonalInformation;
	setPersonalInformation: (value: IPersonalInformation) => void;
}
export const PersonalInformationTabPanel = (props: PersonalTabProps) => {
	const { personalData, setPersonalInformation } = props;
	const [showSaved, setShowSaved] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleCloseEditDialog = () => {
	setShowSaved(false)};

	const [errors, setErrors] = useState({
		name: '',
		lastName: '',
		birthDate: '',
		phone: '',
	  });

	const validateName = (name: string) => {
		if (!name) return 'El nombre es obligatorio';
		if(name && name.length <= 4) return 'El nombre debe tener 4 o más caracteres';
		if(name && name.length >= 20) return 'El nombre debe tener 20 o menos caracteres';
		return '';
	  };
	  
	  const validateLastName = (lastName: string) => {
		if (!lastName) return 'El apellido es obligatorio';
		if(lastName && lastName.length <= 2) return 'El apellido debe tener 2 o más caracteres';
		if(lastName && lastName.length >= 20) return 'El apellido debe tener 20 o menos caracteres';
		return '';
	  };
	  
	  const validateBirthDate = (birthDate: string) => {
		const today = new Date().toISOString().split('T')[0];
		if (!birthDate) return 'La fecha de nacimiento es obligatoria';
		if (birthDate > today) return 'La fecha de nacimiento no puede ser en el futuro';
		return '';
	  };
	  
	  const validateCellphone = (phone: number) => {
		if (!phone) return 'El teléfono es obligatorio';
		if(phone && phone.toString().length <= 6) return 'El teléfono debe tener 6 o más caracteres';

		return '';
	  };

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const newErrors = {
		  name: validateName(personalData.name),
		  lastName: validateLastName(personalData.lastname),
		  birthDate: validateBirthDate(personalData.birthdate),
		  phone: validateCellphone(personalData.phone),
		};	
	
		setErrors(newErrors);
	
		// validando si existen errores
		const isValid = Object.values(newErrors).every((error) => !error);
			//// aca deberia implementar que no se envie la petición en caso de existir errores
		if (isValid) {
			// traje el onSave aqui dentro para que no se disparen peticiones a menos que esté check
			setLoading(true);
		  const rta = await updatePersonalInformation(personalData!);
			rta.status === 201 && setShowSaved(true);
			setLoading(false);
		} else {
		  console.log('Form has errors');
		}
	  };

	return (
		<Grid width="100%">
			<Card sx={{ backgroundColor: '#111111', width: '100%', margin: 'auto', boxShadow: 'none' }} key={'card1'}>
				<Typography
					variant="h5"
					gutterBottom
					sx={{
						color: 'white',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						fontFamily: 'Roboto',
						display: 'flex',
						justifyContent: 'center',
						textWrap: 'nowrap',
					}}>
					Información personal
				</Typography>
			</Card>
			<form onSubmit={handleSubmit}>
			<Card
				sx={{
					height: '45vh',
					backgroundColor: '#111111',
					display: 'flex',
					flexDirection: 'column',
					boxShadow: 'none',
					overflowY: 'auto',
						'&::-webkit-scrollbar': {
							width: 0, // Ancho cero para hacerla invisible
						},
				}} key={'card2'}>
				{!false && [
					<TextField
						name="name"
						label="Nombre del usuario"
						error={!!errors.name}
       					helperText={errors.name}
						sx={{
							marginTop: '1rem',
							width: { xs: '80%', md: '50%' },
							marginLeft: '5%',
							borderTop: 'none',
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
						}}
						InputLabelProps={{
							sx: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
								'&.Mui-focused': {
									color: 'white',
								},
							},
						}}
						size="small"
						defaultValue={personalData?.name}
						onChange={({ target: { value } }) =>
							setPersonalInformation({
								...personalData,
								name: value,
							})
						}
						key={'text1'}
					/>,
					<TextField
						name="lastName"
						label="Apellido del usuario"
						error={!!errors.lastName}
       					helperText={errors.lastName}
						sx={{
							marginTop: '1.5rem',
							width: { xs: '80%', md: '50%' },
							marginLeft: '5%',
							borderTop: 'none',
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
						}}
						InputLabelProps={{
							sx: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
								'&.Mui-focused': {
									color: 'white',
								},
							},
						}}
						size="small"
						defaultValue={personalData?.lastname}
						onChange={({ target: { value } }) =>
							setPersonalInformation({
								...personalData,
								lastname: value,
							})
						}
						key={'text2'}
					/>,
					<TextField
						id="birthDate"
						name="birthDate"
						type="date"
						size="small"
						label="Fecha de nacimiento"
						error={!!errors.birthDate}
       					helperText={errors.birthDate}
						sx={{
							marginTop: '1.5rem',
							width: { xs: '80%', md: '50%' },
							marginLeft: '5%',
							borderTop: 'none',
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
								'& .MuiSvgIcon-root': {
									fill: 'white', // Color del icono
								},
							},
						}}
						InputLabelProps={{
							shrink: true,
							sx: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
								'&.Mui-focused': {
									color: 'white',
								},
							},
						}}
						InputProps={{
							sx: {
								'& input[type="date"]::-webkit-calendar-picker-indicator': {
									filter: 'invert(1) brightness(100%) sepia(100%) saturate(1000%) hue-rotate(180deg)', // Aplicar los estilos al icono de fecha
									color: 'white', // Color blanco para el icono
								},
							},
						}}
						value={personalData?.birthdate ?? '1890-01-01'}
						onChange={({ target: { value } }) =>
							setPersonalInformation({
								...personalData,
								birthdate: value,
							})
						}
						key={'text3'}
					/>,
					<TextField
						name="phone"
						type="tel"
						label="Teléfono"
						error={!!errors.phone}
       					helperText={errors.phone}
						sx={{
							marginTop: '1.5rem',
							marginBottom: '.8rem',
							width: { xs: '80%', md: '50%' },
							marginLeft: '5%',
							borderTop: 'none',
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
						}}
						InputLabelProps={{
							sx: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
								'&.Mui-focused': {
									color: 'white',
								},
							},
						}}
						size="small"
						defaultValue={personalData?.phone}
						onChange={({ target: { value } }) =>
							setPersonalInformation({
								...personalData,
								phone: Number(value) ?? '',
							})
						}
						key={'text4'}
					/>,
				]}

				<Button
					type='submit'
					sx={{
						backgroundColor: '#111111',
						color: 'white',
						borderBottom: '2px inset #B71C1C',
						width: { xs: '80%', md: '50%' },
						margin: 'auto',
						fontSize: { xs: '.6rem', md: '.8rem' },
					}}>
				{!loading ? 'Guardar' : <CircularProgress size={24} color="error" />}
				</Button>
				<Dialog open={showSaved} onClose={handleCloseEditDialog}>
					<Card sx={{ backgroundColor: '#111111', height: '27vh', border: '1px inset #B71C1C' }}>
						<DialogTitle sx={{ textAlign: 'center', color: 'white' }}>Guardado exitosamente</DialogTitle>
						<DialogContent sx={{ textAlign: 'center' }}>
							<Typography sx={{ marginBottom: '1rem', color: 'white' }}>Tus cambios han sido guardados</Typography>
							<img
								style={{ width: '5rem', height: '5rem', filter: 'invert(90%)' }}
								alt="check"
								src="https://images.emojiterra.com/google/noto-emoji/unicode-15/bw/512px/1f468-1f527.png"
								/>
						</DialogContent>
						<Button
							onClick={handleCloseEditDialog}
							sx={{
								backgroundColor: '#111111',
								color: 'white',
								borderBottom: '2px inset #B71C1C',
								width: { xs: '80%', md: '50%' },
								margin: 'auto',
								fontSize: { xs: '.6rem', md: '.8rem' },
								textAlign: 'center',
								display: 'block',
							}}>
							Aceptar
						</Button>
					</Card>
				</Dialog>
			</Card>
								</form>
		</Grid>
	);
};

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import NavBar from '../components/navbar/NavBar';
import { checkSessionStatus } from '../api/authApi';

interface PrivateRouteProps {
	element: JSX.Element;
}
export const PrivateRoutes = (props: PrivateRouteProps) => {
	const { element } = props;
	const navigate = useNavigate();
	const checkSession = async () => {
		try {
			const { request } = await checkSessionStatus();
			if (request.status === 401 && window.location.pathname !== '/login') navigate('/login');
		} catch (error) {
			console.warn('Error al obtener la respuesta:', error);
		}
	};

	useEffect(() => {
		checkSession();
	});

	return [<NavBar key={'navbar'} />, element];
};
